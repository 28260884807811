const projects = [
    {
        id: 1,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project1.png',
        alt: 'Proyecto Santo Domingo',
        title: 'Proyecto Santo Domingo'
    },
    {
        id: 2,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project2.png',
        alt: 'Residencia DL, Dominion, CUU',
        title: 'Residencia DL, Dominion, CUU'
    },
    {
        id: 3,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project3.png',
        alt: 'Edificio AGS',
        title: 'Edificio AGS'
    },
    {
        id: 4,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project4.png',
        alt: 'Residencia Modular, CUU',
        title: 'Residencia Modular, CUU'
    },
    {
        id: 5,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project5.png',
        alt: 'Estructuración Hibrida, Dominion, CUU',
        title: 'Estructuración Hibrida, Dominion, CUU'
    },
    {
        id: 6,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project6.png',
        alt: 'Residencia MC, Bosques de San Francisco, CUU',
        title: 'Residencia MC, Bosques de San Francisco, CUU'
    },
    {
        id: 7,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project7.png',
        alt: 'Plaza Comercial, Cantera, CUU',
        title: 'Plaza Comercial, Cantera, CUU'
    },
    {
        id: 8,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project8.png',
        alt: 'Rancho "El Centenario", CUU',
        title: 'Rancho "El Centenario", CUU'
    },
    {
        id: 9,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project9.png',
        alt: 'Residencia J, Bosques de San Francisco, CUU',
        title: 'Residencia J, Bosques de San Francisco, CUU'
    },
    {
        id: 10,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project10.png',
        alt: 'Aserradero, Parral, CUU',
        title: 'Aserradero, Parral, CUU'
    },
    {
        id: 11,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project11.png',
        alt: 'Departamentos, Parral, CUU',
        title: 'Departamentos, Parral, CUU'
    },
    {
        id: 12,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project12.png',
        alt: 'Muro de Contencion (9m)',
        title: 'Muro de Contencion (9m)'
    },
    {
        id: 13,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project13.png',
        alt: 'Ampliacion, Edificio ISM, CUU',
        title: 'Ampliacion, Edificio ISM, CUU'
    },
    {
        id: 14,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project14.png',
        alt: 'Refuerzo, Edificio ISM, CUU',
        title: 'Refuerzo, Edificio ISM, CUU'
    },
    {
        id: 15,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project15.png',
        alt: 'Cimentaciones Especiales (cisternas contra incendios)',
        title: 'Cimentaciones Especiales (cisternas contra incendios)'
    },
    {
        id: 16,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project16.png',
        alt: 'Residencia Altozano 1, CUU',
        title: 'Residencia Altozano 1, CUU'
    },
    {
        id: 17,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project17.png',
        alt: 'Proyecto Majalca, CUU',
        title: 'Proyecto Majalca, CUU'
    },
    {
        id: 18,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project18.png',
        alt: 'Residencia Altozano S0, CUU',
        title: 'Residencia Altozano S0, CUU'
    },
    {
        id: 19,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project19.png',
        alt: 'Proyecto Especial, Cruce San Jeronimo, CD Juarez, CSS',
        title: 'Proyecto Especial, Cruce San Jeronimo, CD Juarez, CSS'
    },
    {
        id: 20,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project20.png',
        alt: 'Ampliacion IBIS, Merida Centro, Yucatan',
        title: 'Ampliacion IBIS, Merida Centro, Yucatan'
    },
    {
        id: 21,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project21.png',
        alt: 'Malteurop, CUU',
        title: 'Malteurop, CUU'
    }
];

export default projects;
