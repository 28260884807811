const projects = [
    {
        id: 1,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project1.png',
        alt: 'Proyecto Santo Domingo',
        title: 'Proyecto Santo Domingo'
    },
    {
        id: 2,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project2.png',
        alt: 'Residencia DL, Dominion, CUU',
        title: 'Residencia DL, Dominion, CUU'
    },
    {
        id: 3,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project3.png',
        alt: 'Edificio AGS',
        title: 'Edificio AGS'
    },
    {
        id: 4,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project4.png',
        alt: 'Residencia Modular, CUU',
        title: 'Residencia Modular, CUU'
    },
    {
        id: 5,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project5.png',
        alt: 'Estructuración Hibrida, Dominion, CUU',
        title: 'Estructuración Hibrida, Dominion, CUU'
    },
    {
        id: 6,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project6.png',
        alt: 'Residencia MC, Bosques de San Francisco, CUU',
        title: 'Residencia MC, Bosques de San Francisco, CUU'
    },
    {
        id: 7,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project7.png',
        alt: 'Plaza Comercial, Cantera, CUU',
        title: 'Plaza Comercial, Cantera, CUU'
    },
    {
        id: 8,
        url: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/project8.png',
        alt: 'Rancho "El Centenario", CUU',
        title: 'Rancho "El Centenario", CUU'
    }
];

export default projects;
