import Contactinfo from '../components/Contact-info';
import Form from '../components/Form';

export default function Contact() {

    return (
        <div className='container'>
            <Contactinfo />
            <Form />
        </div>
    );
}; 