const services = [
    {
        id: 1,
        img: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/service1.png',
        title: 'PROYECTO ESTRUCTURAL',
        text: 'Coordinación y seguimiento de Proyecto Estructural basado en códigos y normas internacionales. Optimizando los recursos del cliente y brindando seguridad estructural.'
    },
    {
        id: 2,
        img: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/service2.png',
        title: 'DICTAMEN ESTRUCTURAL',
        text: 'Revisión y evaluación de estructuras existentes a fin de llevarlas a que cumplan con la norma vigente emitiendo recomendaciones y respaldo técnico.'
    },
    {
        id: 3,
        img: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/service3.png',
        title: 'SUPERVISIÓN DE OBRA',
        text: 'Supervisión en la ejecución de los trabajos estructurales a fin de que cumplan con lo establecido en el proyecto estructural.'
    },
    {
        id: 4,
        img: 'https://raw.githubusercontent.com/piarenivar/ve-react/main/images/service4.png',
        title: 'CATÁLOGO DE CONCEPTOS',
        text: 'Armado de catálogo de conceptos para paquete de obra y números generadores.'
    }
]

export default services;
